import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Import the plugin here
import { Auth0Plugin } from "./auth";

Vue.config.productionTip = false

const options = {
  "domain": process.env.VUE_APP_AUTH0_DOMAIN || "fivery.jp.auth0.com",
  "clientId": process.env.VUE_APP_AUTH0_CLIENT_ID || "3nLjSZrhFh0mzUiDiL26jCnPZfoglGrd"
};

Vue.use(Auth0Plugin, {
  ...options,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
